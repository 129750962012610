.schedule .schedule_view_container .schedule_monthly_view {
	height: 1px;
	min-height: 100%;
}

.schedule .schedule_view_container .schedule_view .schedule_body {
	height: 1px;
	min-height: 100%;
	position: relative;
}

.schedule .schedule_view_container .schedule_monthly_view table {
	border-collapse: collapse;
	table-layout: fixed;
	font-size: 16px;
	width: 100%;
	min-height: 100%;
}

	.schedule .schedule_view_container .schedule_monthly_view table thead tr td {
		text-align: center;
		font-size: 1.2em;
		padding-top: 4px;
		padding-bottom: 6px;
		border-right: 1px solid #ccc;
		border-bottom: 1px solid #ccc !important;
	}

.schedule .schedule_view_container .schedule_monthly_view .schedule_table_header table thead tr td:last-child,
.schedule .schedule_view_container .schedule_monthly_view .schedule_table_header table tbody tr td:last-child {
	border-right: none;
}

.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr td {
	vertical-align: top;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	padding: 0;
	margin: 0;
}

	.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr td.isToday {
		background: #f8f8f8;
	}

.schedule .schedule_view_container .schedule_view .schedule_body table tbody tr td.selected.isToday {
	background: #6595a4;
	opacity: .8;
}

.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr td .date {
	cursor: pointer;
	padding: 6px;
}

	.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr td .date:hover {
		text-decoration: underline;
	}
	
	.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr td.notInMonth .date {
		color: #ccc;
	}

.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr td.timeSlot {
	font-size: .8em;
	vertical-align: top;
	padding-left: 3px;
}

.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr:last-child td {
	border-bottom: 1px solid #ccc;
}

.schedule .schedule_view_container .schedule_monthly_view .schedule_body table tbody tr td .appointmentCount {
	position: relative;
	left: 30px;
	top: -25px;
	background: #6ba5c2;
	border: 1px solid #1b6981;
	border-radius: 6px;
	overflow: hidden;
	opacity: .98;
	color: white;
	padding: 4px 0 6px 6px;
	width: calc(100% - 60px);
	height: 30px;
	max-height: 30px;
	margin-left: 10px;
	pointer-events: none;
	margin-bottom: -30px;
}

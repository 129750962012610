.error-wrapper {
	font-size: 12px;
	color: #9d1600;
	z-index: 5000;
	font-style: italic;
	margin-top: 2px;
	margin-left: 4px;
}

	.error-wrapper.absolute {
		position: absolute;
		display: block;
		border: 1px solid #fff3d1;
		background-color: #fffcee;
		border-radius: 4px;
		padding-top: 0;
	}

	.error-wrapper.show-icons {
		height: 13px;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkJEMTAyOTNGN0VERjExRThBMzcxQ0FBQjI0MEVEMThBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkJEMTAyOTQwN0VERjExRThBMzcxQ0FBQjI0MEVEMThBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QkQxMDI5M0Q3RURGMTFFOEEzNzFDQUFCMjQwRUQxOEEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QkQxMDI5M0U3RURGMTFFOEEzNzFDQUFCMjQwRUQxOEEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz57ezATAAABtElEQVR42pSRv0tbURTHv++9/PIFNcZiE4gKQdEkak1qC1oHJ10cVJCCupaCLgGpm3QtOLS4CIXSP6DoJC46FAKh2MlGB0mjYOsQNYoY8kNNvt77ntm02rvcc8/5fs45Xy5wz6l2qrH6Gu03/vMMrS02MrnaQhFHH025a7QDsodkHyMBR0Gk7I/hpteXmphKBPl9vZWJZb+c+vEhyNbbVZWX05obLBIgM8/4dswl46f/Aj/trPjFii/p91lN8G8nc1sBGS/fB3lHBqoF9Jy86GZ/RDfBX0Gj0cx4nXyH7gJX07E2MttNFsN80VFlgqkOI5cTDSya8qMi1m7vntkp98Jo1APs5gFdhX5F+H02DA7XAn8uYe10wnFy5dvYzMWFfk+VlO5Qv36Y9wJHl4CqANkysvky7DYF+aNrwCFkh0XMvfOg3qV9rkyd+PLeS+PftoWfZIjMhfmkTjNWPfvZTh53mTWh+bbgk/k3ivSymRCeS2XgomS2EqsiWcTpeQnuiA4UymbeKZxZNbwKb8MiivHJ16lQISNWUhRTcE14PFZhQcG+8CdHGDUSNrcF6Uxp60aAAQCJScHsnjJEIAAAAABJRU5ErkJggg==');
		background-repeat: no-repeat;
	}

	.error-wrapper.absolute.show-icons {
		background-position-y: 1px;
	}

.error-wrapper .error {
	margin-left: 18px;
	line-height: 14px;
	vertical-align: top;
	font-size: 1.2em;
}

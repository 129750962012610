.schedule {
	height: 1px;
	min-height: 100%;
}

.schedule_loading_overlay {
	position: absolute;
	top: 95px;
	width: 100%;
	height: calc(100% - 120px);
	background: rgba(0, 0, 0, 0.3);
}

	.schedule_loading_overlay .pageSpinner {
		position: absolute;
		left: calc(50% - 35px);
		top: 40%;
	}

/*.schedule .schedule_header {
	padding-top: 15px;
	padding-left: 20px;
	padding-bottom: 5px;
	border-bottom: 1px solid #ccc;
}

	.schedule .schedule_header .schedule_header_right {
		float: right;
		margin-right: 20px;
		position: relative;
	}

		.schedule .schedule_header .schedule_header_right .btn-link {
			position: relative;
			top: 15px;
		}

		.schedule .schedule_header .schedule_header_right .btn-outline-primary {
			position: relative;
			top: 7px;
			padding: 4px 2px 4px 2px;
		}

			.schedule .schedule_header .schedule_header_right .btn-outline-primary .far {
				position: relative;
				top: 2px;
			}

			.schedule .schedule_header .schedule_header_right .btn-outline-primary.schedule_display_col {
				-webkit-transform: rotate(90deg);
				-moz-transform: rotate(90deg);
				-o-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}

	.schedule .schedule_header .far {
		display: inline-block;
		font-size: 1.4em;
		position: relative;
		top: 6px;
		margin-left: 5px;
		margin-right: 5px;
		cursor: pointer;
	}

		.schedule .schedule_header .far.fa-plus {
			margin: 0;
			padding: 0;
			margin-left: 15px;
		}

	.schedule .schedule_header input {
		display: inline-block;
		width: 120px;
		text-align: center;
	}

	.schedule .schedule_header .schedule_header_displayDate {
		position: relative;
		top: 3px;
		display: inline-block;
	}
*/

.schedule .schedule_view_container {
	display: inline-block;
	height: 1px;
	min-height: calc(100% - 55px);
}

	.schedule .schedule_view_container .schedule_view {
		height: 1px;
		min-height: calc(100% - 40px);
		width: calc(100% - 200px);
		display: inline-block;
		vertical-align: top;
	}

		.schedule .schedule_view_container .schedule_view .schedule_body table tr td.selected {
			background: #6595a4;
			color: white;
		}

		.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment,
		.schedule .schedule_view_container .schedule_view .schedule_body .movingAppointment {
			position: absolute;
			background: #6ba5c2;
			border: 1px solid #1b6981;
			/*border-radius: 6px;*/
			overflow: hidden;
			opacity: .98;
			color: white;
		}

			.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment.isPending,
			.schedule .schedule_view_container .schedule_view .schedule_body .movingAppointment.isPending {
				background: #ccc;
				border: 1px solid #999;
				color: #212529;
			}

			.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment.isOnClipboard,
			.schedule .schedule_view_container .schedule_view .schedule_body .movingAppointment.isOnClipboard {
				background: #ccc;
				border: 1px solid #999;
				color: #212529;
			}

		.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment.timeBlock,
		.schedule .schedule_view_container .schedule_view .schedule_body .movingAppointment.timeBlock {
			position: absolute;
			background: #535353;
			border: 1px solid #484848;
			border-radius: 6px;
			overflow: hidden;
			opacity: .98;
			color: white;
		}


		.schedule .schedule_view_container .schedule_view .schedule_body .movingAppointment,
		.schedule .schedule_view_container .schedule_view .schedule_body .movingAppointment.timeBlock {
			opacity: .85;
			pointer-events: none;
		}

		.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment .appointment_resize {
			opacity: .45;
			height: 5px;
			min-height: 5px;
			cursor: ns-resize;
		}

		.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment .appointment_body {
			height: 1px;
			min-height: calc(100% - 10px);
			pointer-events: none;
			font-size: 12px;
			line-height: 14px;
			margin-left: 6px;
			margin-right: 4px;
			word-break: break-all;
			overflow: hidden;
		}

			.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment .appointment_body .resouce-color-indicator {
				min-width: 8px;
				min-height: 8px;
				max-height: 8px;
				border: 1px solid rgba(0, 0, 0, .35);
				margin-right: 2px;
				display: inline-block;
			}

			.schedule .schedule_view_container .schedule_view .schedule_body .appointments .appointment .appointment_body .subcontractor-color-indicator {
				min-width: 10px;
				min-height: 10px;
				max-height: 10px;
				border: 1px solid rgba(0, 0, 0, .35);
				margin-right: 3px;
				display: inline-block;
				position: relative;
				top: 2px;
			}

			.schedule .schedule_view_container .schedule_view .schedule_body .context_menu {
				position: absolute;
				font-size: 12px;
				cursor: pointer;
				display: none;
				padding: 5px;
			}

			.schedule .schedule_view_container .schedule_view .schedule_body .context_menu div .fa-plus {
				top: 0px !important;
			}

			.schedule .schedule_view_container .schedule_view .schedule_body .context_menu button {
				font-size: 14px;
				display: block;
				margin-bottom: 4px;
			}

				.schedule .schedule_view_container .schedule_view .schedule_body .context_menu button:last-child {
					margin-bottom: 2px;
				}
				/*.schedule .schedule_view_container .schedule_view .schedule_body .context_menu div:hover {
					background: #e8e8e8;
				}*/
				.schedule .schedule_view_container .schedule_view .schedule_body .appointment_popup {
			position: absolute;
			transition: visibility 0s, opacity 0.15s linear;
			pointer-events: none;
			max-width: 250px;
		}

			.schedule .schedule_view_container .schedule_view .schedule_body .appointment_popup .appointment_popup_arrow {
				width: 0;
				height: 0;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid #ccc;
				position: relative;
				left: 8px;
				top: 1px;
			}

			.schedule .schedule_view_container .schedule_view .schedule_body .appointment_popup .appointment_popup_details {
				position: relative;
				padding: 5px;
				font-size: 12px;
				line-height: 14px;
			}

	.schedule .schedule_view_container .schedule_right {
		display: inline-block;
		width: 195px;
		min-width: 195px;
		height: 1px;
		min-height: 100%;
		vertical-align: top;
		overflow-y: auto;
	}
		.schedule .schedule_view_container .schedule_right .slick-search-bar .btn-link {
			line-height: inherit;
		}
		.schedule .schedule_view_container .schedule_right .clipboard_container {
			display: inline-block;
			width: 195px;
			min-width: 195px;
			height: 1px;
			min-height: calc(15%);
			vertical-align: top;
			overflow-y: auto;
			border-bottom: 1px solid #ccc;
		}

			.schedule .schedule_view_container .schedule_right .clipboard_container .clipboard_title {
				text-align: center;
				margin-top: 8px;
				padding-bottom: 5px;
			}

			.schedule .schedule_view_container .schedule_right .clipboard_container .clipboardAppointment {
				background: #6ba5c2;
				border: 1px solid #1b6981;
				border-radius: 6px;
				overflow: hidden;
				margin-top: 5px;
				width: calc(100% - 20px);
				margin-left: 10px;
				min-height: 60px;
				font-size: 10px;
				padding: 4px;
				color: white;
			}

				.schedule .schedule_view_container .schedule_right .clipboard_container .clipboardAppointment .clipboard_icons {
					float: right;
					margin-right: 5px;
					font-size: 1.4em;
					line-height: 1em;
					cursor: pointer;
				}

		.schedule .schedule_view_container .schedule_right .resources_container,
		.schedule .schedule_view_container .schedule_right .subs_container {
			width: 195px;
			min-width: 195px;
			height: 1px;
			min-height: calc(85%);
			vertical-align: top;
			overflow-y: auto;
			padding-left: 5px;
		}

		.schedule .schedule_view_container .schedule_right .subs_container {
			min-height: calc(100vh - 180px);
		}

			.schedule .schedule_view_container .schedule_right .resources_container .resources_title,
			.schedule .schedule_view_container .schedule_right .subs_container .subs_title {
				text-align: center;
				margin-top: 8px;
				padding-bottom: 5px;
			}

			.schedule .schedule_view_container .schedule_right .resources_container .show_resource,
			.schedule .schedule_view_container .schedule_right .subs_container .show_sub {
				margin-left: 3px;
				line-height: 15px;
			}
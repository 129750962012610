.schedule .schedule_view_container .schedule_daily_view .schedule_table_header {
	width: calc(100% - 18px);
}

	.schedule .schedule_view_container .schedule_daily_view .schedule_table_header table {
		border-collapse: collapse;
		table-layout: fixed;
		font-size: 12px;
		width: 100%;
	}

		.schedule .schedule_view_container .schedule_daily_view .schedule_table_header table thead tr td {
			text-align: center;
			font-size: 1.6em;
			padding-top: 4px;
			padding-bottom: 6px;
			border-right: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}

			.schedule .schedule_view_container .schedule_daily_view .schedule_table_header table thead tr td:last-child {
				border-right: none;
			}

.schedule .schedule_view_container .schedule_daily_view .schedule_body {
	height: 1px;
	min-height: calc(100% - 38px);
	overflow-y: scroll;
	position: relative;
}

	.schedule .schedule_view_container .schedule_daily_view .schedule_body table {
		border-collapse: collapse;
		table-layout: fixed;
		font-size: 12px;
		width: 100%;
	}

		.schedule .schedule_view_container .schedule_daily_view .schedule_body table tr:first-child td {
			border-top: none;
		}

		.schedule .schedule_view_container .schedule_daily_view .schedule_body table tr td:first-child {
			text-align: right;
			padding-right: 5px;
		}

		.schedule .schedule_view_container .schedule_daily_view .schedule_body table tr td {
			height: 20px;
			line-height: 14px;
			border-bottom: 1px dashed #f0f0f0;
			padding: 0;
			margin: 0;
		}

		.schedule .schedule_view_container .schedule_daily_view .schedule_body table tr td {
			border-right: 1px solid #ccc;
		}

			.schedule .schedule_view_container .schedule_daily_view .schedule_body table tr td.timeSlot {
				font-size: .8em;
				vertical-align: top;
				padding-left: 3px;
			}

		.schedule .schedule_view_container .schedule_daily_view .schedule_body table tr:nth-child(4n + 1) td {
			border-bottom: 1px solid #ccc;
		}
